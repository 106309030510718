import React from 'react'
import PropTypes from 'prop-types'
import Slideshow from './Slideshow'

import projectAntibiotics from '../images/project-antibiotics-2.jpeg'

class Main extends React.Component {
  handleSubmit(event) {
    event.preventDefault()

    const data = {
      name: event.target.name.value,
      email: event.target.email.value,
      content: `Contact request from ${event.target.email.value}\n------------\n\n${event.target.content.value}`,
      subject: `WH Foundation contact from ${event.target.name.value}`,
    }

    console.log('sending: ' + JSON.stringify(data))
    //TODO: fix hard coded url
    fetch(
      'https://wavbn69qo2.execute-api.us-west-1.amazonaws.com/dev/email/send',
      {
        method: 'POST',
        body: JSON.stringify(data),
      }
    )
    event.target.reset()
    this.props.onCloseArticle()
  }

  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="welcome"
          className={`${this.props.article === 'welcome' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ height: '66.67vh', overflowY: 'scroll' }}
        >
          <h2 className="major">Welcome</h2>
          <p>
            We extend to you a warm welcome. We are family men and women who are
            deeply touched by the difficulties that many in the world are
            facing, and very especially by those faced by children and youth in
            the areas of health, food, and education.
          </p>
          <p>
            We are sure that you share these concerns with us. As we grow in
            maturity we discover that the richest memories of our lives are
            those moments we spend serving others, making a difference in their
            lives without expecting retribution.
          </p>
          <p>
            Join us and we will work together in the projects of your choosing.
            The needs are so great that nothing is too little or too much.
          </p>
          <p>We thank you in advance,</p>
          <p>
            John Harris <br />
            Executive Director
          </p>
          {close}
        </article>

        <article
          id="mission"
          className={`${this.props.article === 'mission' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ height: '66.67vh', overflowY: 'scroll' }}
        >
          <h2 className="major">Mission</h2>
          <p>
            We assist with the health and education of those in precarious
            circumstances, including children, mothers, women, youth, and
            families, with the goal of aiding them to become self-reliant.
          </p>
          <p>
            We engage our dual mission of health and education upon a solid
            foundation of moral values, evidenced more by what we do than what
            we say. All our efforts acknowledge the precious earth upon which we
            live, and we strive to preserve and enhance it for future
            generations.
          </p>
          <p>
            We welcome all who want to join their efforts with ours, knowing
            that we are stronger together and that selfless service unites and
            bonds hearts and minds toward the greater good--the greatest outcome
            of all humanitarian missions.
          </p>
          <span className="image main">
            <img src={projectAntibiotics} alt="" />
          </span>
          {close}
        </article>

        <article
          id="donations"
          className={`${this.props.article === 'donations' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ height: '66.67vh', overflowY: 'scroll' }}
        >
          <h3 className="major">Donate Here</h3>
          <span className="image main"></span>
          <h4>Donate Here</h4>
          <p>
            To donate you can send funds through Zelle, to the number <b>+1-801-448-3018</b>.
            It will go to the Waterfall Humanitarian Foundation&#39;s account at Wells Fargo
            Bank, and a receipt will be sent to you by the foundation to the name of the
            donor shown by Zelle.
          </p>
          <p>
            You can also send a check to:<br/>
	    <br/>
            <b>
	      Waterfall Humanitarian Foundation<br/>
              1226 Tierney Circle<br/>
    	      Sandy, Utah 84094<br/>
	    </b>
          </p>
          <h4>Financial Commitments</h4>
          <p>
            Donations to a project by individuals are applied to those projects
            100%. If under special circumstances a small part of a donation is
            used in the administration, permission from the donor will be
            sought. The foundation will be administered by volunteers whenever
            possible. As far as possible, administration expenses, salaries and
            payments to suppliers, when necessary, will be paid by donations
            from corporations.
          </p>
          <h4>IRS Deductibility Of Contributions</h4>
          <p>
            The IRS announced in a letter dated November 12, 2014, that the
            foundation is a public charity and is “qualified to receive tax
            deductible bequests, devises, transfers, or gifts,” and that “donors
            can deduct contributions they make under IRC Section 170” from their
            taxes.
          </p>
          <h4>Matching funds by Large Companies</h4>
          <p>
            Some large companies have committed to matching funds donated by
            individuals.
          </p>
          <h4>Personal contribution 100% applied to projects</h4>
          <p>
            The personal contributions from individuals are applied 100% to the
            projects the individual designates. A small percentage of donations
            by corporations, if any, may be used to fund the administration of
            the foundation.
          </p>
          <h4>Working with other organization</h4>
          <p>
            The Foundation identifies organizations with crucial humanitarian
            solutions for health, nutrition and/or education problems affecting
            children worldwide and will create alliances. For getting the
            solution to the children and families worldwide, the foundation will
            cooperate with not-for-profit organizations already on the ground,
            like the Red Cross or charity organizations of churches and
            religious orders.
          </p>
          {close}
        </article>

        <article
          id="projects"
          className={`${this.props.article === 'projects' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ height: '66.67vh', overflowY: 'scroll' }}
        >
          <h2 className="major">Achievements</h2>
          <span className="image main"></span>
          <h4>Project Reports (click on each one for report details)</h4>
          <ul>
          <li>
              <a
                href="/projects/project-venezuela-type1diabetes2"
                target="_blank"
              >
                2021 Report: Helping the children of Venezuela with Type 1 Diabetes.
              </a>
            </li>
            <li>
              <a
                href="/projects/project-venezuela-type1diabetes"
                target="_blank"
              >
                2019 Report: Helping the children of Venezuela with Type 1 Diabetes.
              </a>
            </li>
            <li>
              <a href="/projects/project-venezuela-epilepsy2" target="_blank">
                2021 Report: Bringing epilepsy medication to the children of Venezuela.
              </a>
            </li>
            <li>
              <a href="/projects/project-venezuela-epilepsy" target="_blank">
                2019 Report: Bringing epilepsy medication to the children of Venezuela.
              </a>
            </li>
            <li>
              <a href="/projects/project-venezuela-antibiotics" target="_blank">
                2019 Report: Providing antibiotics to the children in Venezuela.
              </a>
            </li>

          </ul>
          <p>
            The foundation identifies specific projects congruent with our
            mission. At the present time, the most crucial project is to assist
            hospitals dedicated to the treatment of children.
          </p>
          <h4>Hospitals in Venezuela</h4>
          <p>
            There are several hospitals dedicated to children, including the JM
            de los Rios, founded in 1937, in Caracas, and the Latin American
            Children's Cardiology Hospital Dr. Gilberto Rodríguez Ochoa founded
            by President Hugo Chavez in 2006. Outside these specialized
            pediatric hospitals, other children are treated in other hospitals
            and clinics that are divided geographically as follows:
            approximately 634 in Caracas, Maracaibo 195, 173 in Valencia, and 92
            in Barquisimeto.
          </p>
          <h4>Critical Medicines for children with cancer</h4>
          <p>
            The foundation is attempting to provide critical medicines several
            children hospitals in Venezuela. For instance, in just the
            Anzoategui Hospital there are eight children from 19 months to 14
            years of age, with common acute lymphoblastic leukemia that need to
            fulfill their Leucovorin chemotherapy protocol. Other needs are
            significantly simpler, yet equally crucial. For instance, at the
            Pediatric Endocrinology department of the Children's Hospital JM de
            los Rios, they need instruments for measuring blood glucose
            (glucometers) and reactive tapes for these devices for patients with
            diabetes mellitus. In time, other needs will be identified, and will
            be addressed according to the urgency of the need, and the
            possibilities of the foundation.
          </p>
          <p>
            The achievements of the foundation, while important to those who
            benefited, have been modest. Funding has been very limited, and with
            exception of minimal administration costs, all has gone to help the
            needy.
          </p>
          <p>No salaries have been paid.</p>
          {close}
        </article>

        <article
          id="reports"
          className={`${this.props.article === 'reports' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ height: '66.67vh', overflowY: 'scroll' }}
        >
          <h2 className="major">Reports</h2>
          <span className="image main"></span>
          <p>
            <a href="/reports/biannual/20191010" target="_blank">
              Biannual Report for October 10, 2019
            </a>
            <br />
          </p>
          <p>
            <a href="/reports/biannual/20210504" target="_blank">
              Biannual Report for May 5, 2021
            </a>
            <br />
          </p>
          <p>
            <a href="/reports/yearend/2021" target="_blank">
              End of Year Report for 2021
            </a>
            <br />
          </p>
          <p>
            <a href="reports/yearend/2021/spanish" target="_blank">
              Informe de Fin de Año para 2021
            </a>
          </p>
          <p>
            <a href="reports/ukraine" target="_blank">
              Report: Gordon Duval in Ukraine
            </a>
          </p>
          <p>
           <a href="2023-april-interim-report.pdf" target="_blank">
              Report: April 2023 Interim Report (English & Spanish)
           </a>
          </p>
          <p>
           <a href="story-of-the-beginning-of-the-waterfall-foundation.pdf" target="_blank">
              Report: The Story of the Waterfall Foundation and how it began
           </a>
          </p>
          {close}
        </article>

        <article
          id="org"
          className={`${this.props.article === 'org' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ height: '66.67vh', overflowY: 'scroll' }}
        >
          <h2 className="major">Organization</h2>
          <span className="image main"></span>
          <a href="/trustees" target="_blank">
            Trustees
          </a>
          <p>
            &ensp; John Harris, Executive Director
            <br />
            &ensp; John Buckley, J.D., Trustee
            <br />
            &ensp; Carlos H. Amado (Seventy Emeritus), Trustee
            <br />
            &ensp; Cynthia Lange, J.D.
            <br />
            &ensp; Dennis Lange, M.D.
            <br />
          </p>
          <a href="/advisors" target="_blank">
            Advisors
          </a>
          <p>
            &ensp; Paul Bell, R.N., B.S.N
            <br />
            &ensp; Gordon Duval (Son), M.D.
            <br />
            &ensp; Michael Duval, J.D.
            <br />
            &ensp; Jeffrey Elmer, M.D.
            <br />
            &ensp; Daniel Faber, M.D.
            <br />
            &ensp; Alen Gibbons, B.A., M.B.A.
            <br />
            &ensp; Augusto Pereira Gonzalez, M.D.
            <br />
            &ensp; Nathan Gwilliams, MBA, BGS
            <br />
            &ensp; Johnnie Harris, B.S.
            <br />
            &ensp; Fadoul Mazzawi, CEO
            <br />
            &ensp; Bill Mournes, B.S.
            <br />
            &ensp; Rosaria Martone, J.D.
            <br />
            &ensp; Dennis Tenney, M.B.A.
            <br />
            &ensp; Sharlene Wells Hawkes
            <br />
          </p>
          <a href="/admin" target="_blank">
            Administration
          </a>
          <p>
            &ensp; Shandon Gubler, Ph.D.
            <br />
          </p>
          <a href="/intmembers" target="_blank">
            International Members
          </a>
          <p>
            &ensp; Yvonne Maria Sarmiento, Attorney at Law
            <br />
            &ensp; Noemi Dargalaye. (E.E.Eng.)  External International Logistics
            Advisor
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form
            method="post"
            onSubmit={this.handleSubmit.bind(this)}
            ref="contactForm"
          >
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="content" id="content" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a href="#disable" className="icon fa-twitter">
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="#disable" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="#disable" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a href="#disable" className="icon fa-github">
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>

        <article
          id="images"
          className={`${this.props.article === 'images' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ height: '100vh', width: '40vw', overflowY: 'scroll'}}
        >
          <div
            style={{ height: '50vh', width: '50vh', margin: 'auto'}}
          >
            <h2 className="major">Images</h2>
            <Slideshow />
          </div>
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
